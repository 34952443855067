import { createContext, useState, useContext, useEffect } from 'react';
import {
  useGetClient,
  useGetProduct,
  useUpdateClient,
} from '../services/query/invoice';
import { useLocation } from 'react-router-dom';

const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const clientLists = JSON.parse(localStorage.getItem('clientList') || '[]');
  // const ProductLists = JSON.parse(localStorage.getItem('productList') || '[]');

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#EBAA03');
  const [showAddItems, setShowAddItems] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [edit, setEdit] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [isAccount, setIsAccount] = useState('');
  const [clientId, setClientId] = useState('');
  const [currentClient, setCurrentClient] = useState('');
  const [currentProduct, setCurrentProduct] = useState([]);
  const [productId, setProductId] = useState('');
  const [active, setActive] = useState(false);
  const [showUpdate, setShowUpdate] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [chargeFee, setChargeFee] = useState(false);
  const [isPartialPaymentSet, setIsPartialpaymentSet] = useState(false);

  const { mutate: clientMutate, data: clientData } = useGetClient();
  const { mutate: productMutate, data: productData } = useGetProduct();

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const addClient = (newItem) => {
    const newItems = [newItem, ...clientList];
    setClientList(newItems);
  };
  const addProduct = (newItem) => {
    const newItems = [newItem, ...productList];
    setProductList(newItems);
  };
  const addSelectedProduct = (newItem) => {
    const newItems = [newItem, ...selectedProduct];
    setSelectedProduct(newItems);
  };

  const filteredProductList = (id) => {
    const newItems = selectedProduct?.filter((item, index) => index !== id);
    setSelectedProduct(newItems);
  };
  const isLightColor = (color) => {
    const rgb = parseInt(color?.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 200;
  };

  return (
    <AppContext.Provider
      value={{
        open,
        setOpen,
        setShowAddItems,
        selectedColor,
        clientList,
        setClientList,
        productList,
        selectedProduct,
        filteredProductList,
        setInvoiceInfo,
        setIsAccount,
        selectedClient,
        setSelectedClient,
        invoiceInfo,
        isAccount,
        handleColorSelect,
        isLightColor,
        edit,
        setEdit,
        show,
        setIsShow,
        showAddItems,
        setShowProductDetails,
        setEditProduct,
        isShow,
        setShow,
        addClient,
        addProduct,
        showProductDetails,
        editProduct,
        setProductList,
        addSelectedProduct,
        active,
        setActive,
        clientMutate,
        clientData,
        setClientId,
        setCurrentClient,
        setCurrentProduct,
        currentProduct,
        currentClient,
        clientId,
        setProductId,
        productId,
        productData,
        productMutate,
        showUpdate,
        setShowUpdate,
        setTotalAmount,
        totalAmount,
        chargeFee,
        setChargeFee,
        isPartialPaymentSet,
        setIsPartialpaymentSet,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
