import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Tr,
  Td,
  Image,
  Button,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  paymentLinkHeader,
  viewpaymentLinkHeader,
} from '../../common/constants';
import TableFormat from '../../common/TableFormat';
import { useNavigate } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import SingleLinkTransDetail from '../../modals/SingleLinkTransDetail';
import ReportTrans from '../../modals/ReportTrans';
import { useGetAllTransactions } from '../../../services/query/transactions';
import { useGetUser } from '../../../services/query/account';

const SingleTransactionTableLayer = ({ reference, linkDetails }) => {
  const { data: userData } = useGetUser();

  const [category, setCategory] = useState('');
  const [showCat, setShowCat] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const [startValue, startChange] = useState('');
  const [showEndDate, setShowEndDate] = useState(false);
  const [endValue, endChange] = useState('');
  const [duration, setDuration] = useState('');

  const [show, setShow] = useState(false);
  const [details, setDetails] = useState('');

  const [page, setPage] = useState(1);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(0);
  const [limit, setLimit] = useState(25);

  const { data, isLoading, refetch } = useGetAllTransactions(
    userData?.data?.accounts[0]?.id,
    page,
    limit,
    startValue ? formatDates(new Date(startValue)) : '',
    endValue ? formatDates(new Date(endValue)) : '',
    category === 'CREDIT' ? category : category === 'DEBIT' ? 'DEBIT' : '',
    category === 'CREDIT' ? '' : category === 'DEBIT' ? '' : category,
    reference,
    'SUCCESSFUL'
  );

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    const currentPage = page;
    const itemsPerPage = limit;
    const totalItems = data?.meta?.itemCount;

    const currentStartRow = (currentPage - 1) * itemsPerPage + 1;
    const currentEndRow = Math.min(currentPage * itemsPerPage, totalItems);

    setStartRow(currentStartRow);
    setEndRow(currentEndRow);
  }, [data, page, limit]);

  const [openPayment, setOpenPayment] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const openMenu = (index) => {
    setDetails(index);
    setOpenPayment(true);
  };

  useEffect(() => {
    const calculateDates = () => {
      const currentDate = new Date();
      let startDate;

      if (duration.includes('3 months')) {
        startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 3);
      } else if (duration.includes('6 months')) {
        startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 6);
      } else {
        startChange('');
        endChange('');
      }

      if (startDate) {
        startChange(startDate.toISOString().split('T')[0]);
        endChange(currentDate.toISOString().split('T')[0]);
      }
    };

    calculateDates();
  }, [duration]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.box') === null) {
        setShowDate(false);
        setShowCat(false);
        setShowStartDate(false);
        setShowEndDate(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const formatDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-US', dateOptions);

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = dateObj.toLocaleTimeString('en-US', timeOptions);

    return {
      date: formattedDate, // Example: "17 Oct, 2023"
      time: formattedTime, // Example: "10:32 AM"
    };
  };

  return (
    <Box
      border="1px solid #F2F2F2"
      borderRadius="16px"
      bg="#fff"
      mt="24px"
      overflow="hidden"
    >
      <Flex
        align="center"
        flexWrap="wrap"
        justifyContent="space-between"
        px="14px"
        py="22px"
        gap="10px"
      >
        <Box display={'flex'} gap={'5px'}>
          <Text
            fontSize={{ base: '12px', md: '16px' }}
            fontWeight={500}
            color="#2463EB"
            lineHeight={'32px'}
          >
            Payment Link Transaction
          </Text>
        </Box>
      </Flex>
      <TableFormat
        header={paymentLinkHeader}
        paginationValues={{
          startRow,
          endRow,
          total: data?.meta?.itemCount,
          page: data?.meta?.page,
          pageCount: data?.meta?.pageCount,
          onNext: () =>
            data?.meta?.page !== data?.meta?.pageCount
              ? setPage(page + 1)
              : null,
          onPrevious: () => (data?.meta?.page !== 1 ? setPage(page - 1) : null),
          setLimit,
          limit,
        }}
        useDefaultPagination
        act
        isLoading={isLoading}
      >
        {data?.data?.length > 0 ? (
          data?.data?.map((item, i) => (
            <Tr key={item.id} fontSize="13px" color="#333">
              <Td>
                <Box>
                  <Text color="black" fontWeight="500">
                    {formatDateTime(item.createdAt).date}
                  </Text>
                  <Text color="#828282" fontSize="12px">
                    {formatDateTime(item.createdAt).time}
                  </Text>
                </Box>
              </Td>
              <Td>
                <Box>
                  <Text fontSize="14px" fontWeight={500}>
                    {item?.sourceAccountName || 'N/A'}
                  </Text>
                </Box>
              </Td>
              <Td color="#1E1E1E" fontWeight="500">
                {item?.type === 'DEBIT' ? 'Outflow' : 'Inflow'}
              </Td>
              <Td color="black" fontWeight="500">
                ₦{' '}
                {Number(item?.amount)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </Td>

              <Td>
                <Text
                  py="4px"
                  px="16px"
                  rounded="8px"
                  bgColor={
                    item?.status === 'ACTIVE'
                      ? '#E7F7EF'
                      : item?.status === 'PENDING'
                      ? '#FFF6D3'
                      : '#FFC7C0'
                  }
                  color={
                    item?.status === 'ACTIVE'
                      ? '#0CAF60'
                      : item?.status === 'PENDING'
                      ? '#E6BB20'
                      : '#FF3030'
                  }
                >
                  {item?.status}
                </Text>
              </Td>
              <Td>
                <Menu>
                  <MenuButton as={Text} cursor="pointer">
                    <FiMoreVertical />
                  </MenuButton>
                  <MenuList
                    borderRadius="4px"
                    p="10px"
                    zIndex={6}
                    border="2px solid #F4F6F8"
                    boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
                  >
                    <MenuItem
                      onClick={() => {
                        openMenu(item);
                      }}
                      key={item.id}
                      color="#2463EB"
                      borderRadius="8px"
                      border="2px solid #F4F6F8"
                      mb="8px"
                    >
                      View Transactions
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={7} rowSpan={2} border="none">
              <Flex align="center" flexDir="column" justifyContent="center">
                <Image mt="40px" src="/assets/rocket.svg" w="120px" h="120px" />
                <Text
                  color="#1E1E1E"
                  mt="16px"
                  fontWeight={600}
                  mb="8px"
                  fontSize="18px"
                >
                  No Pending Payment
                </Text>
                <Text color="#545B6A" fontSize="14px">
                  You do not currently have any transactions.
                </Text>
              </Flex>
            </Td>
          </Tr>
        )}
      </TableFormat>
      <SingleLinkTransDetail
        details={details}
        isOpen={openPayment}
        setOpenReport={setOpenReport}
        onClose={() => {
          setOpenPayment(false);
        }}
      />
      <ReportTrans
        isOpen={openReport}
        setOpenPayment={setOpenPayment}
        onClose={() => {
          setOpenReport(false);
        }}
      />
    </Box>
  );
};
export default SingleTransactionTableLayer;
